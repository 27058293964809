import {Injectable} from '@angular/core';
import {AuthInfo} from './auth-info';
import {BehaviorSubject} from 'rxjs';
import {AmplifyService} from 'aws-amplify-angular';
import {AppService} from '../app.service';
import {PermissionsService} from '../services/permissions.service';
import {Auth} from 'aws-amplify';
import {first, last, take} from 'rxjs/operators';

@Injectable()
export class AuthService {
  static UNKNOWN_USER = new AuthInfo(null, null, null, null, null, null);
  authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(AuthService.UNKNOWN_USER);
  currentCountry = 'USA';
  userId = '';
  currentCompany = '';
  userRole;
  companyStatus = 'pending';

  constructor(private amplifyService: AmplifyService,
              private appService: AppService,
              private permissionsService: PermissionsService) {
    if (JSON.parse(localStorage.getItem('currentCountry'))) {
      this.currentCountry = JSON.parse(localStorage.getItem('currentCountry'));
    } else {
      localStorage.setItem('currentCountry', JSON.stringify(this.currentCountry));
    }

    this.amplifyService.authState()
      .pipe(
        take(1)
      )
      .subscribe(authState => {
        console.log(authState);

        if (authState.state === 'signedIn') {
          var userEmail;

          if (authState.state === 'signedIn'
            && authState.user
            && authState.user.attributes
            && authState.user.attributes.email) {
            userEmail = authState.user.attributes.email;
          }

          // console.log(userEmail);

          this.appService.userGetByEmail(userEmail)
            .subscribe(data => {
              console.log(data);

              this.userId = data['data']['userGetByEmail']['user']['id'];

               Auth.updateUserAttributes(authState.user, {
                 'custom:user_id': this.userId
               }).then(data => {
                 console.log(data);
               });

              this.appService.systemAuthEnter(this.userId)
                .subscribe(data => {
                  console.log(data);
                }, error => {
                  console.log(error);
                });

              this.appService.companyGetByUser(this.userId)
                .subscribe(data => {
                  if (JSON.parse(localStorage.getItem('currentCompany'))) {
                    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
                  } else {
                    this.currentCompany = data['data']['companyGetByUser'] && data['data']['companyGetByUser']['user']['companies'][0]['id'];
                  }

                  this.appService.companyGetById(this.currentCompany)
                    .subscribe(data => {
                      this.companyStatus = data['data']['companyGetById'] && data['data']['companyGetById']['company']['status'];

                      this.permissionsService.permissionsGetUserRoles(this.userId, this.currentCompany)
                        .subscribe(data => {
                          const isStaff = data['data']['permissionsGetUserRoles']['user']['permissions']['roles']
                            .find(item => {
                              return (item['title'] === 'Super Admin') || (item['title'] === 'Operator');
                            });

                          if (isStaff) {
                            this.userRole = isStaff['title'];
                          } else {
                            this.userRole = data['data']['permissionsGetUserRoles'] && data['data']['permissionsGetUserRoles']['user']['permissions']['roles'][0]['title'];
                          }

                          const authInfo = new AuthInfo(authState.user.attributes.email,
                            JSON.parse(localStorage.getItem('currentCountry')),
                            this.currentCompany,
                            this.userId,
                            this.userRole,
                            this.companyStatus);

                          this.authInfo$.next(authInfo);
                        });
                    });
                });
            }, error => {
              console.log(error);
            });
        }
      });
  }

  getUserRole() {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser({
        bypassCache: true
      }).then(user => {
        this.appService.userGetByEmail(user.attributes.email)
          .subscribe(data => {
            this.userId = data['data']['userGetByEmail']['user']['id'];

            this.appService.companyGetByUser(this.userId)
              .subscribe(data => {
                if (JSON.parse(localStorage.getItem('currentCompany'))) {
                  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
                } else {
                  this.currentCompany = data['data']['companyGetByUser'] && data['data']['companyGetByUser']['user']['companies'][0]['id'];
                }

                this.permissionsService.permissionsGetUserRoles(this.userId, this.currentCompany)
                  .subscribe(data => {
                    const isStaff = data['data']['permissionsGetUserRoles']['user']['permissions']['roles']
                      .find(item => {
                        return (item['title'] === 'Super Admin') || (item['title'] === 'Operator');
                      });

                    if (isStaff) {
                      this.userRole = isStaff['title'];
                    } else {
                      this.userRole = data['data']['permissionsGetUserRoles'] && data['data']['permissionsGetUserRoles']['user']['permissions']['roles'][0]['title'];
                    }

                    console.log(this.userRole);

                    resolve(this.userRole);
                  });
              });
            }, error => {
            console.log(error);
          });
      });
    });
  }
}
