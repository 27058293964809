import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {AuthService} from '../../shared/auth.service';
import {AppService} from '../../app.service';
import {CompaniesService} from '../../services/companies.service';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.scss']
})
export class DeleteUserModalComponent implements OnInit {
  isLoading = true;
  @Output() close = new EventEmitter();
  companyId;
  company;
  user;
  users = [];

  constructor(@Inject('id') public id,
              @Inject('data') public data,
              private authService: AuthService,
              private appService: AppService,
              private companiesService: CompaniesService) { }

  ngOnInit() {
    this.isLoading = true;

    this.appService.companyGetByUser(this.id)
      .subscribe(data => {
        // console.log(data);

        this.getUser(this.id);

        this.companyId = data['data']['companyGetByUser'] && data['data']['companyGetByUser']['user']['companies'][0]['id'];

        if (this.companyId) {
          this.appService.companyGetById(this.companyId)
            .subscribe(data => {
              // console.log(data);

              this.company = data['data']['companyGetById']['company'];
            });

          this.companiesService.getUsersOnce(this.companyId)
            .subscribe(data => {
              // console.log(data);

              this.isLoading = false;

              this.users = data['data']['companyGetUsers']['company']['users'];
            });
        } else {
          this.isLoading = false;
        }
      }, (error) => {
        this.isLoading = false;

        console.log('there was an error sending the query', error);
      });
  }

  getUser(id) {
    this.companiesService.userGetById(this.id).valueChanges
      .subscribe(data => {
        // console.log(data);

        this.user = data['data']['userGetById']['user'];

        this.isLoading = false;
      });
  }

  closeModal(): void {
    this.close.emit();
  }

  proceed() {
    this.close.emit();

    this.data.next(this.id);
  }
}
