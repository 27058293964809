import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SigninComponent} from './components/signin/signin.component';
import {SignupComponent} from './components/signup/signup.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ConfirmSignupComponent} from './components/confirm-signup/confirm-signup.component';
import {SignupDuplicateComponent} from './components/signup-duplicate/signup-duplicate.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {AuthGuard} from './shared/auth.guard';
import {CreateCompanyComponent} from './components/create-company/create-company.component';

const routes: Routes = [
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'signup-duplicate',
    component: SignupDuplicateComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'confirm-signup',
    component: ConfirmSignupComponent,
  },
  {
    path: 'create-company',
    canActivate: [AuthGuard],
    component: CreateCompanyComponent,
  },
  {
    path: 'home',
    canLoad: [AuthGuard],
    loadChildren: './modules/home/home.module#HomeModule'
  },
  {
    path: 'operator',
    canLoad: [AuthGuard],
    loadChildren: './modules/operator/operator.module#OperatorModule'
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: 'management/rounds',
    redirectTo: 'operator/rounds',
    pathMatch: 'full'
  },
  {
    path: 'debcred/upload',
    redirectTo: 'home/upload',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'signin'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
