import {Component, OnDestroy, OnInit} from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from 'carbon-components-angular';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, timer} from 'rxjs';
import {Auth} from 'aws-amplify';
import {AppService} from '../../app.service';
import {RoundsService} from '../../services/rounds.service';
import {CompaniesService} from '../../services/companies.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: [
    '../auth/auth.scss',
    './signin.component.scss'
  ]
})
export class SigninComponent implements OnInit, OnDestroy {
  signIn = false;
  formGroup: FormGroup;
  isLoading = false;
  currentStep;
  userId;
  companyId;
  private subscription: Subscription = new Subscription();
  signedIn = false;
  returnUrl;
  redirectStatuses;
  // redirectStatuses = {
  //   companyStatus: 'customer',
  //   docsUploaded: false,
  //   vendors: false,
  //   roundStatus: 'upload',
  //   entries: null,
  //   role: 'View'
  // };
  company;
  lastRoundId;

  constructor(private router: Router,
              private amplifyService: AmplifyService,
              protected formBuilder: FormBuilder,
              private notificationService: NotificationService,
              public translate: TranslateService,
              private appService: AppService,
              private route: ActivatedRoute,
              private roundsService: RoundsService,
              private companiesService: CompaniesService) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['return_url'];

    if (JSON.parse(localStorage.getItem('currentStep'))) {
      this.currentStep = JSON.parse(localStorage.getItem('currentStep'));
    }

    this.formGroup = this.formBuilder.group({
      email: ['', [
          Validators.required,
          Validators.pattern(/.*\S+.*/),
          Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')
        ]
      ],
      password: ['', [
          Validators.required,
          Validators.minLength(8),
        ]
      ],
      remember: [true],
    });

    this.subscription.add(this.amplifyService.authStateChange$
      .subscribe(authState => {
        this.signIn = authState.state === 'signedIn';

        if (this.signIn) {
          this.subscription.add(timer(1000).subscribe(time => {
            Auth.currentUserInfo()
              .then(user => {
                this.userId = user.attributes['custom:user_id'];

                this.appService.companyGetByUser(this.userId)
                  .subscribe(data => {
                    this.isLoading = false;

                    this.companyId = data['data']['companyGetByUser'] && data['data']['companyGetByUser']['user']['companies'][0]['id'];

                    if (this.companyId) {
                      this.companiesService.getCompanyById(this.companyId)
                        .subscribe(data => {
                          // console.log(data);

                          this.company = data['data']['companyGetById'] && data['data']['companyGetById']['company'];

                          this.roundsService.getLastRound(this.company['country']).valueChanges
                            .subscribe(data => {
                              // console.log(data);

                              this.lastRoundId = data['data']['roundGetLast'] && data['data']['roundGetLast']['round']['id'];

                              this.appService.userGetStatuses(this.companyId, this.lastRoundId)
                                .subscribe(data => {
                                  // console.log(data);

                                  this.redirectStatuses = data['data']['userGetStatuses'];

                                  if (this.returnUrl) {
                                    this.router.navigateByUrl(this.returnUrl);
                                  } else {
                                    this.handleRedirect(this.redirectStatuses);

                                    // this.router.navigate(['/home']);
                                  }
                                }, (error) => {
                                  console.log('there was an error sending the query', error);
                                });
                              }, (error) => {
                              console.log('there was an error sending the query', error);
                            });
                        }, (error) => {
                          console.log('there was an error sending the query', error);
                        });
                    } else {
                      this.router.navigate(['/create-company']);
                    }
                  }, (error) => {
                    this.showNotification('error', error);

                    console.log('there was an error sending the query', error);
                  });
              })
              .catch(err => {
                this.isLoading = false;

                this.showNotification('error', err['message']);
              });

          }, (error) => {
            this.showNotification('error', error);

            console.log('there was an error sending the query', error);
          }));

          localStorage.setItem('signedin', JSON.stringify(true));
        }
      }, (error) => {
        this.showNotification('error', error);

        console.log('there was an error sending the query', error);
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isErrorVisible(field, error, select?) {
    if (select) {
      return this.formGroup.controls[field].touched
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else {
      return this.formGroup.controls[field].dirty
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    }
  }

  showNotification(type, message) {
    this.notificationService.showNotification({
      type,
      title: 'Auth',
      message,
      target: '.notification-container',
      duration: 30000
    });
  }

  createRequest(formValue) {
    this.signedIn = true;

    this.isLoading = true;

    Auth.signIn(String(formValue.email).toLowerCase(), formValue.password)
      .then(user => {
        this.isLoading = false;

        this.amplifyService.setAuthState({ state: 'signedIn', user });
      })
      .catch(err => {
        this.signedIn = true;

        this.isLoading = false;

        if (err['message'] == 'User is not confirmed.') {
          this.router.navigate(['/confirm-signup'], { queryParams: { username: String(this.formGroup.get('email').value).toLowerCase() } });

          return;
        }
        this.showNotification('error', err['message']);
      });
  }

  clearStorage() {
    localStorage.removeItem('auth');
    localStorage.removeItem('userId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('assigned');
    localStorage.removeItem('currentStep');
  }

  checkUser() {
    this.isLoading = true;

    if (this.formGroup.get('email').untouched) {
      this.isLoading = false;

      return;
    }

    this.userIsRegistered();
  }

  userIsRegistered() {
    this.isLoading = true;

    if (!String(this.formGroup.get('email').value).toLowerCase()) {
      this.isLoading = false;

      return;
    }

    this.appService.userIsRegistered(String(this.formGroup.get('email').value).toLowerCase())
      .subscribe(data => {
        this.isLoading = false;

        if (data['data']['userIsRegistered'] === 7) {
          this.router.navigate(['/confirm-signup'], { queryParams: { username: String(this.formGroup.get('email').value).toLowerCase() } });
        }
      }, (error) => {
        this.isLoading = false;

        console.log('there was an error sending the query', error);
      });
  }

  isCustomer(status) {
    return status === 'customer';
  }

  hasDocuments(documents) {
    return documents === true;
  }

  hasVendors(vendors) {
    return vendors === true;
  }

  isUpload(status) {
    return status === 'upload';
  }

  isProcessed(status) {
    return status === 'after_pr' || status === 'finalized';
  }

  hasUploads(uploads) {
    return uploads === true;
  }

  isAdmin(role) {
    return role === 'Admin';
  }

  isViewer(role) {
    return role === 'View';
  }

  handleRedirect(data) {
    // console.log(data);
    //
    // console.log(this.isCustomer(data.companyStatus));
    // console.log(this.hasDocuments(data.docsUploaded));
    // console.log(this.hasVendors(data.vendors));
    // console.log(this.isUpload(data.roundStatus));
    // console.log(this.hasUploads(data.entries));
    // console.log(!this.isViewer(data.role));

    if (!data) {
      this.router.navigate(['/home']);

      return;
    }

    if (this.isCustomer(data.companyStatus)
      && this.isUpload(data.roundStatus)
      && !this.isViewer(data.role)) {
      this.router.navigate(['/home/upload']);

      return;
    }

    if (this.isCustomer(data.companyStatus)
      && this.isProcessed(data.roundStatus)) {
      this.router.navigate(['/home/settlement']);

      return;
    }

    if (!this.hasDocuments(data.docsUploaded )
      && this.isAdmin(data.role)) {
      this.router.navigate(['/home'], { queryParams: { section: 'documents' }});

      return;
    }

    if (this.hasDocuments(data.docsUploaded)
      && !this.hasVendors(data.vendors)) {
      this.router.navigate(['/home'], { queryParams: { section: 'vendors'}});

      return;
    }

    if (!this.isCustomer(data.companyStatus)
      && this.hasDocuments(data.docsUploaded)
      && this.hasVendors(data.vendors)) {
      this.router.navigate(['/home'], { queryParams: { section: 'documents' }});

      return;
    }

    if (this.isCustomer(data.companyStatus)
      && this.isUpload(data.roundStatus)) {
      this.router.navigate(['/home/debts']);

      return;
    }

    if (this.hasDocuments(data.docsUploaded)
      && this.hasVendors(data.vendors)) {
      this.router.navigate(['/404']);

      return;
    }

    this.router.navigate(['/home']);
  }
}
