import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class RoleGuard implements CanActivateChild, CanActivate {
  authInfo;

  constructor(private router: Router,
              private authService: AuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.getUserRole().then(userRole => {
      if (next.data.roles.indexOf(userRole) === -1) {
        this.router.navigate(['/home/upload']);

        return false;
      }

      return true;
    });
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.getUserRole().then(userRole => {
      if (next.data.roles.indexOf(userRole) === -1) {
        this.router.navigate(['/home/upload']);

        return false;
      }

      return true;
    });
  }
}
