import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  oneOf(value: any, allowedValues: any[]) {
    return allowedValues.some((allowedValue) => allowedValue === value);
  }

  retry(handler: (sub: Subscriber<any>) => void) {
    const obs = new Observable((sub) => {
      handler(sub);
    }).pipe(
      retry()
    );

    return obs;
  }

  delay(ms){
    let id = null;
    const promise = new Promise((r) => {
      id = setTimeout(r,ms);
    });
    return {
      promise,
      cancel() {
        clearTimeout(id);
      }
    };
  }

  storageGet(key: string): object {
    return JSON.parse(localStorage.getItem(key) || '{}');
  }

  storageSet(key: string, value: object){
    localStorage.setItem(key, JSON.stringify(value));
  }

}
