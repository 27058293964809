export class AuthInfo {
  constructor(public email: string,
              public currentCountry: string,
              public currentCompany: string,
              public userId: string,
              public userRole: string,
              public companyStatus: string) {}

  getCurrentCountry() {
    return this.currentCountry;
  }
}
