import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-delete-company-modal',
  templateUrl: './delete-company-modal.component.html',
  styleUrls: ['./delete-company-modal.component.scss']
})
export class DeleteCompanyModalComponent implements OnInit {
  @Output() close = new EventEmitter();

  constructor(@Inject('id') public id,
              @Inject('data') public data) { }

  ngOnInit() {
  }

  closeModal(): void {
    this.close.emit();
  }

  proceed() {
    this.close.emit();

    this.data.next(this.id);
  }
}
