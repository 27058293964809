import {Component, ElementRef, Inject, OnDestroy, OnInit, Renderer, ViewChild} from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../shared/auth.service';
import {AuthInfo} from '../../shared/auth-info';
import {languageMap} from '../../translation';
import {AppService} from '../../app.service';
import {PermissionsService} from '../../services/permissions.service';
import {CompaniesService} from '../../services/companies.service';
import {RoundsService} from '../../services/rounds.service';
import {forkJoin, Subject, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
declare const require;
import { Auth } from 'aws-amplify';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from 'carbon-components-angular';
import {environment} from '../../../environments/environment';
const MINUTES_UNITL_AUTO_LOGOUT = environment.idleInterval; // in mins
const CHECK_INTERVAL = 30000; // in ms
const STORE_KEY = 'lastAction';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user;
  signedIn = false;
  currentCountry = 'USA';
  countries = [];
  userId;
  companyId;
  company;
  companies = [];
  currentCompany;
  currentCompanyName;
  userRole;
  round;
  private subscription: Subscription = new Subscription();
  lang = 'en';
  langs = {
    en: 'English',
    mk: 'Македонски',
    hr: 'Hrvatski',
    sr: 'Srpski',
    cn: 'Crnogorski',
    el: 'Ελληνικα'
  };
  langsCodes = Object.keys(this.langs);
  usersCompany;
  @ViewChild('search', { read: ElementRef, static: true }) el: ElementRef;
  customInput = new Subject();
  isLoading = false;
  formGroup: FormGroup;
  authInfo;
  queryLang;
  section;
  userCompanies = [];
  requests = [];

  public getLastAction() {
    return JSON.parse(localStorage.getItem(STORE_KEY));
  }

  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router,
              private amplifyService: AmplifyService,
              private authService: AuthService,
              private appService: AppService,
              private permissionsService: PermissionsService,
              private companiesService: CompaniesService,
              private roundsService: RoundsService,
              public translate: TranslateService,
              private route: ActivatedRoute,
              private renderer: Renderer,
              @Inject(DOCUMENT) private document,
              protected formBuilder: FormBuilder,
              private notificationService: NotificationService) {
    this.authService.authInfo$
      .subscribe(authInfo => {
        this.authInfo = authInfo;

        authInfo.userId && this.companiesService.userGetByIdOnce(authInfo.userId)
          .subscribe(userData => {

            console.log('User get result', userData);
            if (userData) {
              this.lang = languageMap.fromServer(userData['data']['userGetById']['user']['language']) || 'en';

              this.translate.addLangs(this.langsCodes);

              this.translate.setDefaultLang(this.lang);

              if (JSON.parse(localStorage.getItem('lang'))) {
                this.lang = JSON.parse(localStorage.getItem('lang'));

                this.translate.use(this.lang);
              } else {
                if (this.langsCodes.includes(this.translate.getBrowserLang())) {
                  this.lang = this.translate.getBrowserLang();

                  this.translate.use(this.lang);
                } else {
                  this.translate.use(this.lang);
                }
              }

              this.queryLang = this.route.snapshot.queryParams['lang'];

              if (this.queryLang) {
                localStorage.setItem('lang', JSON.stringify(this.queryLang));

                this.translate.setDefaultLang(this.lang);

                this.translate.use(this.queryLang);
              }

              this.renderer.setElementClass(
                this.document.body,
                this.lang,
                true);
            }
        }, (error) => {
            console.log('there was an error sending the query', error);
          });
    }, (error) => {
        console.log('there was an error sending the query', error);
      });
  }

  isStaff(role) {
    return role === 'Super Admin' || role === 'Operator';
  }

  isAdmin(role) {
    return role === 'Admin' || role === 'Upload';
  }

  isViewer(role) {
    return role === 'View';
  }

  isUpload(status) {
    return status === 'upload' || status === 'after_pr';
  }

  isModify(status) {
    return status === 'upload' || status === 'after_pr' || status === 'finalized';
  }

  isCustomer(status) {
    return status === 'customer';
  }

  selectValue() {
    // alert('2')
    this.el.nativeElement.querySelector('#company input').select();
  }

  searchBy(e) {
    // alert('1')
    this.companies = [];

    this.customInput.next({
      value: e.target && e.target.value ? e.target.value : ''
    });
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      country: ['', Validators.required],
    });

    this.customInput.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(data => {
      this.searchCompanies(data['value'] ? data['value'] : '');
    });

    this.countries = this.appService.getCountries();

    this.authService.authInfo$
      .subscribe(authInfo => {
        this.authInfo = authInfo;

        this.currentCountry = authInfo.currentCountry;

        this.currentCompany = authInfo.currentCompany;

        this.userRole = authInfo.userRole;

        if (this.authInfo.userId) {
          this.initListener();
          this.initInterval();
          // this.check();
          localStorage.setItem(STORE_KEY, Date.now().toString());
        }

        if (authInfo.email) {
          this.appService.userGetByEmail(authInfo.email)
            .subscribe(data => {
              this.userId = data['data']['userGetByEmail']['user']['id'];

              if (authInfo.userRole === 'Operator') {
                this.appService.getOperatorCountries(this.userId)
                  .subscribe(data => {
                    if (data['data']['getOperatorCountries']) {
                      this.countries = this.countries.filter(item => {
                        return data['data']['getOperatorCountries']['countries'].indexOf(item['code']) > -1;
                      });
                    } else {
                      this.countries = [];
                    }
                  });
              }

              this.appService.companyGetByUser(this.userId)
                .subscribe(data => {
                  if (authInfo.currentCompany) {
                    this.companyId = authInfo.currentCompany;
                  } else {
                    this.companyId = data['data']['companyGetByUser'] && data['data']['companyGetByUser']['user']['companies'][0]['id'];
                  }

                  this.route.queryParams.subscribe(params => {
                    if (params['company_id']) {
                      this.getCompanyInfo(params['company_id'], this.currentCountry);
                    } else {
                      this.getCompanyInfo(this.companyId, this.currentCountry);
                    }
                  });

                  this.requests = [];

                  data['data']['companyGetByUser']['user']['companies']
                    .map(item => {
                      this.requests.push(this.appService.companyGetById(item['id']));
                    });

                  forkJoin(this.requests)
                    .subscribe(data => {
                      this.userCompanies = data.map(item => item['data']['companyGetById']['company']);
                    });

                    // this.permissionsService.permissionsGetUserRoles(this.userId, this.companyId)
                    // .subscribe(data => {
                    //   this.userRole = data['data']['permissionsGetUserRoles'] && data['data']['permissionsGetUserRoles']['user']['permissions']['roles'][0]['title'];
                    // });
                });

              
            }, (error) => {
              console.log('there was an error sending the query', error);
            });

        }

        if (this.currentCountry) {
          this.getCompanies();
        }
      }, (error) => {
        console.log('there was an error sending the query', error);
      });
  }

  getCompanies() {
    this.companies = [];

    this.companiesService.getCompaniesOnce({ country: this.currentCountry }, {page: 1, pageSize: 100000 })
      .subscribe(data => {
        this.companies = data['data']['companyGetByFilter']['companies'].map(item => {
          return {
            id: item.id,
            content: item.name,
            name: item.name
          };
        });
      });
  }

  searchCompanies(term) {
    this.isLoading = true;

    if (this.currentCountry) {
      this.companies = [];

      this.companiesService.searchCompanies(term, { country: this.currentCountry })
        .subscribe(data => {
          // console.log(data);

          this.isLoading = false;

          this.companies = data['data']['searchCompanyByName'].map(item => {
            return {
              id: item.id,
              content: item.name,
              name: item.name
            };
          });
        });
    }
  }

  setCurrentCountry(currentCountry) {
    if (!currentCountry['item']) {
      return false;
    }

    this.isLoading = true;

    localStorage.setItem('currentCountry', JSON.stringify(currentCountry['item']['code']));

    this.currentCountry = currentCountry['item']['code'];

    setTimeout(data => {
      if (this.el.nativeElement.querySelector('#country input')) {
        this.el.nativeElement.querySelector('#country input').value = this.currentCountry;
      }

      this.isLoading = false;

      // if (this.el.nativeElement.querySelector('#company input')) {
      //   this.el.nativeElement.querySelector('#company input').value = '';
      // }
    }, 500);

    // this.authService.authInfo$.next(new AuthInfo(
    //   this.authInfo.email,
    //   JSON.parse(localStorage.getItem('currentCountry')),
    //   JSON.parse(localStorage.getItem('currentCompany')),
    //   null,
    //   this.userRole,
    //   null
    // ));
  }

  companySelected(company) {
    if (!company['item']) {
      return false;
    }

    this.isLoading = true;

    this.router.navigate(['/home'], { queryParams: { company_id: company.item.id } });

    localStorage.setItem('currentCompany', JSON.stringify(company.item.id));

    this.currentCompany = company.item.id;

    this.appService.companyGetById(this.currentCompany)
      .subscribe(data => {
        this.company = data['data']['companyGetById'] && data['data']['companyGetById']['company'];

        this.isLoading = false;
        // this.authService.authInfo$.next(new AuthInfo(
        //   this.authInfo.email,
        //   // this.company['country'],
        //   JSON.parse(localStorage.getItem('currentCountry')),
        //   JSON.parse(localStorage.getItem('currentCompany')),
        //   null,
        //   this.userRole,
        //   this.company['status']
        // ));
      });
  }

  updateAuthInfo(company, section) {
    if (company) {
      localStorage.setItem('currentCompany', JSON.stringify(company));
    }

    this.section = section;

    this.authService.authInfo$.next(new AuthInfo(
      this.authInfo.email,
      // this.company['country'],
      JSON.parse(localStorage.getItem('currentCountry')),
      company ? company : JSON.parse(localStorage.getItem('currentCompany')),
      null,
      this.userRole,
      this.company['status']
    ));
  }


  logout() {
    Auth.signOut().then(user => {
      localStorage.removeItem('currentCompany');

      localStorage.removeItem('signedin');

      this.authService.authInfo$.next(
        new AuthInfo(null, null, null, null, null, null)
      );

      this.amplifyService.setAuthState({ state: 'signedOut', user });

      this.router.navigate(['/signin']);

      setTimeout(() => {
        window.location.reload();
      }, 100);

    }, error => {
      console.log(error);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showNotification(type, message) {
    this.notificationService.showNotification({
      type,
      title: 'Header',
      message,
      target: '.notification-container',
      duration: 30000
    });
  }

  getCompanyInfo(companyId, currentCountry) {
    this.appService.companyGetById(companyId)
      .subscribe(data => {
        this.company = data['data']['companyGetById'] && data['data']['companyGetById']['company'];

        this.currentCompanyName = this.company['name'];

        if (this.section === 'country') {
          if (this.el.nativeElement.querySelector('#company input')) {
            this.el.nativeElement.querySelector('#company input').value = '...';

            this.el.nativeElement.querySelector('#company input').select();
          }
        } else {
          if (this.el.nativeElement.querySelector('#company input')) {
            this.el.nativeElement.querySelector('#company input').value = this.currentCompanyName;
          }
        }

        this.roundsService.getLastRound(currentCountry).valueChanges
          .subscribe(data => {
            if (data['data']['roundGetLast']) {
              this.round = data['data']['roundGetLast']['round'];
            } else {
              this.round = null;
            }
          }, (error) => {
            error.graphQLErrors.map((e) => {
              if (e.errorInfo && e.errorInfo.statusCode === 1204) {
                this.showNotification('error', `${this.translate.instant('upload.file.noround')}`);
              } else {
                this.showNotification('error', error);
              }
            });

            console.log('there was an error sending the query', error);
          });
      });
  }

  setCurrentLanguage(lang) {
    localStorage.setItem('lang', JSON.stringify(lang));

    if (lang) {
      this.authInfo.userId && this.companiesService.userGetByIdOnce(this.authInfo.userId)
        .subscribe((userData) => {
          userData['data']['userGetById']['user']['language'] !== languageMap.fromServer(lang)
          && this.companiesService.modifyUser(this.authInfo.userId, {
            language: languageMap.toServer(lang),
          }, this.authInfo.currentCompany)
            .subscribe(modifyData => {
              console.log('Language modify result', modifyData);
              if (modifyData) {
                this.renderer.setElementClass(
                  this.document.body,
                  'en',
                  false);

                this.renderer.setElementClass(
                  this.document.body,
                  'mk',
                  false);

                this.renderer.setElementClass(
                  this.document.body,
                  'hr',
                  false);

                this.renderer.setElementClass(
                  this.document.body,
                  'sr',
                  false);

                this.renderer.setElementClass(
                  this.document.body,
                  'cn',
                  false);

                this.renderer.setElementClass(
                  this.document.body,
                  'el',
                  false);

                this.renderer.setElementClass(
                  this.document.body,
                  lang,
                  true);

                this.translate.setDefaultLang(lang);

                this.translate.use(lang);
              }
            }, (error) => {
              console.log('there was an error sending the query', error);
            });
        }, (error) => {
          console.log('there was an error sending the query', error);
        });
    }
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {
      this.logout();
    }
  }
}
