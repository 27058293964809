// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://yy7zsftdanhrjmfq5cguepjway.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:6f4be5be-2c42-404b-8980-b1d81fbe8f65",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lVgMErw47",
    "aws_user_pools_web_client_id": "2lbq08plvdi7b8h0u9rrdjn9ij",
};


export default awsmobile;
