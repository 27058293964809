export const languageMap = {
  app: {
    en: "en-gb",
    mk: "mk",
    hr: "hr",
    sr: "sr",
  },
  fromServer(language) {
    for (const appValue in this.app) {
      if (language === this.app[appValue]) {
        return appValue;
      }
    }
  },
  toServer(language) {
    // return this.app[language];
    return language;
  },
};
