import {Component, OnDestroy, OnInit} from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from 'carbon-components-angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {Subscription, timer} from 'rxjs';
import {Auth} from 'aws-amplify';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  email;
  code;
  password;
  codeSent = false;
  emailChanged = false;
  isLoading = false;
  formGroup: FormGroup;
  serviceDesk = environment.serviceDesk;
  username;
  private subscription: Subscription = new Subscription();

  constructor(private router: Router,
              private amplifyService: AmplifyService,
              private notificationService: NotificationService,
              protected formBuilder: FormBuilder,
              public translate: TranslateService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.email = JSON.parse(localStorage.getItem('username'));

    this.route.queryParams.subscribe(params => {
      this.codeSent = params['codeSent'];

      this.emailChanged = params['emailChanged'];
      }, (error) => {
      this.showNotification('error', error);

      console.log('there was an error sending the query', error);
    });

    this.formGroup = this.formBuilder.group({
      email: ['', [
        Validators.pattern(/.*\S+.*/),
        Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')
      ]],
      code: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/),
      ]],
    });
  }

  sendCodeAgain() {
    this.isLoading = true;

    Auth.resendSignUp(String(this.email).toLowerCase() || String(this.formGroup.get('email').value).toLowerCase())
      .then((data: any) => {
        this.isLoading = false;

        this.showNotification('success', this.translate.instant('verification.code.sent'));
      })
      .catch(err => {
        this.isLoading = false;

        this.showNotification('error', err['message']);
      });
  }

  forgot() {
    this.isLoading = true;

    this.amplifyService
      .auth()
      .forgotPassword(String(this.email).toLowerCase())
      .then(() => {
        this.isLoading = false;

        this.codeSent = true;

        localStorage.setItem('email', JSON.stringify(String(this.email).toLowerCase()));
      })
      .catch(err => {
        this.isLoading = false;

        this.showNotification('error', err['message']);

        this.codeSent = false;
      });
  }

  createRequest(formData) {
    this.isLoading = true;

    if (this.emailChanged) {
      Auth.verifyCurrentUserAttributeSubmit('email', formData.code)
        .then((user: any) => {
          this.showNotification('success', 'Email was verified');

          this.subscription.add(timer(2000).subscribe(data => {
            this.isLoading = false;

            this.router.navigate(['/home']);
          }));
        })
        .catch(err => {
          this.isLoading = false;

          this.showNotification('error', err['message']);
        });

      return;
    }

    this.amplifyService
      .auth()
      .forgotPasswordSubmit(
        String(this.email).toLowerCase() || String(this.formGroup.get('email').value).toLowerCase(),
        formData.code,
        formData.password
      )
      .then(() => {
        this.showNotification('success', this.translate.instant('verify.success'));

        this.subscription.add(timer(2000).subscribe(data => {
          this.isLoading = false;

          this.router.navigate(['/signin']);
        }));

        localStorage.removeItem('email');
      })
      .catch(err => {
        this.isLoading = false;

        this.showNotification('error', err['message']);
      });
  }

  showNotification(type, message) {
    this.notificationService.showNotification({
      type,
      title: this.translate.instant('verify.section'),
      message,
      target: '.notification-container',
      duration: 30000
    });
  }

  isErrorVisible(field, error, select?) {
    if (select) {
      return this.formGroup.controls[field].touched
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else {
      return this.formGroup.controls[field].dirty
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
