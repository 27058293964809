import {AfterContentChecked, Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: 'input[inputAutoFocus]'
})
export class InputFocusDirective implements OnInit {
  constructor(private element: ElementRef<HTMLInputElement>) {}

  ngOnInit(): void {
    this.element.nativeElement.focus();
  }
}
