import {Component, Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class DebCredsService {
  createDebCredMutation = gql`mutation CreateDebCred($data: DebCredInput) {
        createDebCred(data: $data)
      }`;

  createDebCredsMutation = gql`mutation CreateDebCreds(
    $input: [DebCredInput]
    $replace: Boolean
    $file: String
    $version: String
  ) {
    createDebCreds(
      input: $input
      replace: $replace
      file: $file
      version: $version
    ) {
      rowsInserted
      brokenRows {
        __row_number
        __error_message
        round_id
        run_number
        reporting_company_id
        reporting_company_country
        reporting_company_tax_id
        debcred_company_country
        debcred_company_tax_id
        debcred_company_name
        amount
        currency
        invoice_number
        due_date
        allocation
        notes
      }
    }
  }`;

  modifyDebCredMutation = gql`mutation ModifyDebCred(
    $company: CompanyIdInput
    $debcred: DebCredModifyInput
  ) {
    modifyDebCred(company: $company, debcred: $debcred) {
      before {
        amount
        invoice_number
        due_date
        allocation
        notes
      }
      after {
        amount
        invoice_number
        due_date
        allocation
        notes
      }
    }
  }`;

  deleteDebCredsMutation = gql`mutation DeleteDebCreds($company: CompanyIdInput, $debcreds: IdsListInput) {
    deleteDebCreds(company: $company, debcreds: $debcreds) {
      ids
    }
  }`;

  viewDebCredsQuery = gql`query DebCredList {
        debCredList {
          list {
            id
            reporting_company_country
            reporting_company_tax_id
            debcred_company_country
            debcred_company_tax_id
            debcred_company_name
            currency
            invoice_number
            due_date
            allocation
            notes
            amount
          }
          totalCount
        }
      }`;

  listCollectiblesAndPayablesQuery = gql`query ListCollectiblesAndPayables(
    $round: RoundId,
    $myCompany: CompanyIdentInput,
    $options: SelectOptions,
    $filter: CompanyFilterInput,
    $search: String
  ) {
    listCollectiblesAndPayables(
      round: $round,
      myCompany: $myCompany,
      options: $options,
      filter: $filter,
      search: $search
    ) {
      collectibles {
        company_id
        company_tax_id
        company_name
        country
        your_collectible_amount
        your_payable_amount
        their_collectible_amount
        their_payable_amount
        currency
      }
      count
      page
      pageSize
    }
  }`;

  listCollectiblesAndPayablesBulkQuery = gql`query ListCollectiblesAndPayablesBulk(
    $round: RoundId
    $myCompany: CompanyIdentInput
    $options: SelectOptions
    $filter: CompanyFilterInput
    $search: String
  ) {
    listCollectiblesAndPayablesBulk(
      round: $round
      myCompany: $myCompany
      options: $options
      filter: $filter
      search: $search
    ) {
      collectibles {
        company_id
        company_tax_id
        company_name
        country
        your_collectible_amount
        your_payable_amount
        their_collectible_amount
        their_payable_amount
        currency
        status
        has_contacts
        listInvoices {
          invoices {
            id
            your_collectible_amount
            your_payable_amount
            their_collectible_amount
            their_payable_amount
            currency
            invoice_number
            due_date
            allocation
            notes
            last_updated
            last_updated_by
          }
        }
      }
      count
      page
      pageSize
    }
  }`;

  listInvoicesQuery = gql`query ListInvoices($round: RoundId, $myCompany: CompanyIdentInput, $otherCompany: CompanyIdentInput) {
    listInvoices(round: $round, myCompany: $myCompany, otherCompany: $otherCompany) {
      invoices {
        id
        your_collectible_amount
        your_payable_amount
        their_collectible_amount
        their_payable_amount
        currency
        invoice_number
        due_date
        allocation
        notes
        last_updated_by
      }
      count
      page
      pageSize
    }
  }`;

  listSettlementsByPayingCompanyQuery = gql`query listSettlementsByPayingCompany($myCompany: CompanyIdentInput, $round: RoundIdentInput) {
    listSettlementsByPayingCompany (round: $round, myCompany: $myCompany) {
      id
      roundId
      payingCompanyId
      payingCompanyTaxId
      payingCompanyName
      payingCompanyCountry
      collectingCompanyId
      collectingCompanyTaxId
      collectingCompanyName
      collectingCompanyCountry
      settledPayableAmount
      settledCollectibleAmount
      payingCompanyReductionRequest
      collectingCompanyReductionRequest
      reducedAmount
      currency
      country
      roundStatus
    }
  }`;

  listSettlementsBulkQuery = gql`query ListSettlementsBulk(
    $myCompany: CompanyIdentInput
    $round: RoundIdentInput
  ) {
    listSettlementsBulk(myCompany: $myCompany, round: $round) {
      collectibles {
        id
        roundId
        payingCompanyId
        payingCompanyTaxId
        payingCompanyName
        payingCompanyCountry
        collectingCompanyId
        collectingCompanyTaxId
        collectingCompanyName
        collectingCompanyCountry
        settledPayableAmount
        settledCollectibleAmount
        payingCompanyReductionRequest
        collectingCompanyReductionRequest
        reducedAmount
        currency
        roundStatus
        country
        listInvoices {
          invoices {
            id
            your_collectible_amount
            your_payable_amount
            their_collectible_amount
            their_payable_amount
            currency
            invoice_number
            due_date
            allocation
            notes
            last_updated
            last_updated_by
          }
          count
          page
          pageSize
        }
      }
      payables {
        id
        roundId
        payingCompanyId
        payingCompanyTaxId
        payingCompanyName
        payingCompanyCountry
        collectingCompanyId
        collectingCompanyTaxId
        collectingCompanyName
        collectingCompanyCountry
        settledPayableAmount
        settledCollectibleAmount
        payingCompanyReductionRequest
        collectingCompanyReductionRequest
        reducedAmount
        currency
        roundStatus
        country
        listInvoices {
          invoices {
            id
            your_collectible_amount
            your_payable_amount
            their_collectible_amount
            their_payable_amount
            currency
            invoice_number
            due_date
            allocation
            notes
            last_updated
            last_updated_by
          }
          count
          page
          pageSize
        }
      }
    }
  }`;

  listFinalReductionsBulkQuery = gql`query ListFinalReductionsBulk(
    $myCompany: CompanyIdentInput
    $round: RoundIdentInput
  ) {
    listFinalReductionsBulk(myCompany: $myCompany, round: $round) {
      collectibles {
        id
        roundId
        payingCompanyId
        payingCompanyTaxId
        payingCompanyName
        payingCompanyCountry
        collectingCompanyId
        collectingCompanyTaxId
        collectingCompanyName
        collectingCompanyCountry
        settledPayableAmount
        settledCollectibleAmount
        payingCompanyReductionRequest
        collectingCompanyReductionRequest
        reducedAmount
        currency
        roundStatus
        country
        listInvoices {
          invoices {
            id
            your_collectible_amount
            your_payable_amount
            their_collectible_amount
            their_payable_amount
            currency
            invoice_number
            due_date
            allocation
            notes
            last_updated
            last_updated_by
          }
          count
          page
          pageSize
        }
      }
      payables {
        id
        roundId
        payingCompanyId
        payingCompanyTaxId
        payingCompanyName
        payingCompanyCountry
        collectingCompanyId
        collectingCompanyTaxId
        collectingCompanyName
        collectingCompanyCountry
        settledPayableAmount
        settledCollectibleAmount
        payingCompanyReductionRequest
        collectingCompanyReductionRequest
        reducedAmount
        currency
        roundStatus
        country
        listInvoices {
          invoices {
            id
            your_collectible_amount
            your_payable_amount
            their_collectible_amount
            their_payable_amount
            currency
            invoice_number
            due_date
            allocation
            notes
            last_updated
            last_updated_by
          }
          count
          page
          pageSize
        }
      }
    }
  }`;

  listSettlementsByCollectingCompanyQuery = gql`query listSettlementsByCollectingCompany($myCompany: CompanyIdentInput, $round: RoundIdentInput) {
    listSettlementsByCollectingCompany (round: $round, myCompany: $myCompany) {
      id
      roundId
      payingCompanyId
      payingCompanyTaxId
      payingCompanyName
      payingCompanyCountry
      collectingCompanyId
      collectingCompanyTaxId
      collectingCompanyName
      collectingCompanyCountry
      settledPayableAmount
      settledCollectibleAmount
      payingCompanyReductionRequest
      collectingCompanyReductionRequest
      reducedAmount
      currency
      country
      roundStatus
    }
  }`;

  listFinalReductionsByPayingCompanyQuery = gql`query ListFinalReductionsByPayingCompany(
    $myCompany: CompanyIdentInput
    $round: RoundIdentInput
  ) {
    listFinalReductionsByPayingCompany(myCompany: $myCompany, round: $round) {
      id
			roundId
      payingCompanyId
      payingCompanyTaxId
      payingCompanyName
      payingCompanyCountry
    	collectingCompanyId
      collectingCompanyTaxId
      collectingCompanyName
      collectingCompanyCountry
      reducedAmount
      currency
      roundStatus
      country
    }
  }`;

  listFinalReductionsByCollectingCompanyQuery = gql`query ListFinalReductionsByCollectingCompany(
    $myCompany: CompanyIdentInput
    $round: RoundIdentInput
  ) {
    listFinalReductionsByCollectingCompany(myCompany: $myCompany, round: $round) {
      id
			roundId
      payingCompanyId
      payingCompanyTaxId
      payingCompanyName
      payingCompanyCountry
    	collectingCompanyId
      collectingCompanyTaxId
      collectingCompanyName
      collectingCompanyCountry
      reducedAmount
      currency
      roundStatus
      country
    }
  }`;

  requestSettlementReductionMutation = gql`mutation RequestSettlementReduction($settlementReduction: SettlementReductionInput) {
        requestSettlementReduction(settlementReduction: $settlementReduction)
      }`;

  constructor(private apollo: Apollo) {}

  createDebCred(data) {
    console.log(data);

    return this.apollo.mutate({
      mutation: this.createDebCredMutation,
      variables: {
        data: data
      }
    });
  }

  createDebCreds(input, roundId, myCompanyId, action?, options?, file?, version?) {
    console.log(file);

    // console.log(input);
    return this.apollo.mutate({
      mutation: this.createDebCredsMutation,
      variables: {
        input: input,
        replace: action === 'replace',
        file,
        version
      },
      refetchQueries: [{
        query: this.listCollectiblesAndPayablesBulkQuery,
        variables: {
          round: {
            id: roundId
          },
          myCompany: {
            id: myCompanyId
          },
          options
        },
      }]
    });
  }

  modifyDebCred(companyId, debcred, roundId, options?) {
    // console.log(input);
    return this.apollo.mutate({
      mutation: this.modifyDebCredMutation,
      variables: {
        company: {
          id: companyId
        },
        debcred
      },
      refetchQueries: [{
        query: this.listCollectiblesAndPayablesBulkQuery,
        variables: {
          round: {
            id: roundId
          },
          myCompany: {
            id: companyId
          },
          options
        },
      }]
    });
  }

  deleteDebCreds(companyId, id, roundId, options?) {
    // console.log(input);
    return this.apollo.mutate({
      mutation: this.deleteDebCredsMutation,
      variables: {
        company: {
          id: companyId
        },
        debcreds: {
          ids: [id]
        },
      },
      refetchQueries: [{
        query: this.listCollectiblesAndPayablesBulkQuery,
        variables: {
          round: {
            id: roundId
          },
          myCompany: {
            id: companyId
          },
          options
        },
      }]
    });
  }

  viewDebCredList() {
    return this.apollo.query({
      query: this.viewDebCredsQuery
    });
  }

  listCollectiblesAndPayables(roundId, myCompanyId, options?, search?) {
    // console.log(roundId);
    // console.log(myCompanyId);

    console.log(options);

    return this.apollo.watchQuery({
      query: this.listCollectiblesAndPayablesQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        myCompany: {
          id: myCompanyId
        },
        options,
        search
      }
    });
  }

  listCollectiblesAndPayablesBulk(roundId, myCompanyId, options?, search?) {
    // console.log(roundId);
    // console.log(myCompanyId);

    console.log(options);

    return this.apollo.watchQuery({
      query: this.listCollectiblesAndPayablesBulkQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        myCompany: {
          id: myCompanyId
        },
        options,
        search
      }
    });
  }

  uploadedCollectiblesAndPayables(roundId, myCompanyId) {
    // console.log(roundId);
    // console.log(myCompanyId);

    return this.apollo.watchQuery({
      query: this.listCollectiblesAndPayablesBulkQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        myCompany: {
          id: myCompanyId
        }
      }
    });
  }

  listInvoices(roundId, myCompanyId, otherCompanyId) {
    // console.log(roundId);
    // console.log(myCompanyId);
    // console.log(otherCompanyId);

    return this.apollo.query({
      query: this.listInvoicesQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        myCompany: {
          id: myCompanyId
        },
        otherCompany: {
          id: otherCompanyId
        }
      }
    });
  }

  listSettlementsByPayingCompany(roundId, myCompanyId) {
    // console.log(roundId);
    // console.log(myCompanyId);

    // roundId = 135;
    // myCompanyId = 212;

    return this.apollo.query({
      query: this.listSettlementsByPayingCompanyQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        myCompany: {
          id: myCompanyId
        }
      }
    });
  }

  listSettlementsBulk(roundId, myCompanyId) {
    return this.apollo.query({
      query: this.listSettlementsBulkQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        myCompany: {
          id: myCompanyId
        }
      }
    });
  }

  listFinalReductionsBulk(roundId, myCompanyId) {
    return this.apollo.query({
      query: this.listFinalReductionsBulkQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        myCompany: {
          id: myCompanyId
        }
      }
    });
  }

  listSettlementsByCollectingCompany(roundId, myCompanyId) {
    // console.log(roundId);
    // console.log(myCompanyId);

    // roundId = 135;
    // myCompanyId = 212;

    return this.apollo.query({
      query: this.listSettlementsByCollectingCompanyQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        myCompany: {
          id: myCompanyId
        }
      }
    });
  }

  listFinalReductionsByPayingCompany(roundId, myCompanyId) {
    // console.log(roundId);
    // console.log(myCompanyId);

    // roundId = 135;
    // myCompanyId = 212;

    return this.apollo.query({
      query: this.listFinalReductionsByPayingCompanyQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        myCompany: {
          id: myCompanyId
        }
      }
    });
  }

  listFinalReductionsByCollectingCompany(roundId, myCompanyId) {
    // console.log(roundId);
    // console.log(myCompanyId);

    // roundId = 135;
    // myCompanyId = 212;

    return this.apollo.query({
      query: this.listFinalReductionsByCollectingCompanyQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        myCompany: {
          id: myCompanyId
        }
      }
    });
  }

  requestSettlementReduction(id, companyId, amount) {
    // console.log(id);
    // console.log(companyId);
    // console.log(amount);

    return this.apollo.mutate({
      mutation: this.requestSettlementReductionMutation,
      variables: {
        settlementReduction: {
            id,
            companyId,
            amount
        }
      }
    });
  }
}
