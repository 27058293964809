import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class CompaniesService {
  getCompaniesQuery = gql`query CompanyGetByFilter(
    $company: CompanyFilterInput
    $options: SelectOptions
    $search: String
  ) {
    companyGetByFilter(company: $company, options: $options, search: $search) {
      companies {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
      }
      count
      page
      pageSize
    }
  }`;

  getCompaniesOnceQuery = gql`query CompanyGetByFilter(
    $company: CompanyFilterInput
    $options: SelectOptions
    $search: String
  ) {
    companyGetByFilter(company: $company, options: $options, search: $search) {
      companies {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
      }
    }
  }`;

  searchCompaniesQuery = gql`query SearchCompanyByName(
    $search: String
    $filter: CompanyFilterInput
  ) {
    searchCompanyByName(search: $search, filter: $filter) {
      id
      taxId
      country
      name
      address
      isCustomer
      canBePrepaid
      isGovernmental
      status
    }
  }`;

  getCompanyByIdQuery = gql`query CompanyGetById($company: CompanyIdentInput, $options: SelectOptions) {
    companyGetById(company: $company, options: $options) {
      company {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
      }
    }
  }`;

  getUserByIdQuery = gql`query UserGetById($user: UserIdentInput, $options: SelectOptions) {
    userGetById(user: $user, options: $options) {
      user {
        id
        firstName
        lastName
        address
        email
        phone
        lastLogin
        language
        created
      }
    }
  }`;

  getUsersQuery = gql`query CompanyGetUsers($company: CompanyIdentInput, $options: SelectOptions) {
    companyGetUsers(company: $company, options: $options) {
      company {
        id
        users {
          id
        }
      }
    }
  }`;

  addCompanyMutation = gql`mutation AddCompany($company: CompanyInput) {
    addCompany(company: $company) {
      company {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
      }
    }
  }`;

  modifyCompanyMutation = gql`mutation ModifyCompany($company: CompanyModifyInput) {
    modifyCompany(company: $company) {
      company {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
      }
    }
  }`;

  modifyCompanyTaxIdMutation = gql`mutation ModifyCompanyTaxId($company: CompanyTaxIdInput) {
    modifyCompanyTaxId(company: $company) {
      company {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
        deleted
      }
    }
  }`;

  deleteCompanyMutation = gql`mutation DeleteCompany($company: CompanyIdInput) {
    deleteCompany(company: $company) {
      company {
        id
        deleted
      }
    }
  }`;

  activateCompanyMutation = gql`mutation ActivateCompany($company: CompanyIdInput) {
    activateCompany(company: $company) {
      id
    }
  }`;

  convertCompanyToCustomerMutation = gql`mutation ConvertCompanyToCustomer($company: CompanyIdentInput) {
    convertCompanyToCustomer(company: $company) {
      id
    }
  }`;

  convertCompanyBackFromCustomerMutation = gql`mutation ConvertCompanyBackFromCustomer($company: CompanyIdentInput) {
    convertCompanyBackFromCustomer(company: $company) {
      id
    }
  }`;

  companySetStatusDeclinedMutation = gql`mutation CompanySetStatusDeclined($company: CompanyIdentInput) {
    companySetStatusDeclined(company: $company) {
      id
    }
  }`;

  companySetStatusPendingMutation = gql`mutation CompanySetStatusPending($company: CompanyIdentInput) {
    companySetStatusPending(company: $company) {
      id
    }
  }`;

  addUserMutation = gql`mutation AddUser($user: UserAddInput) {
    addUser(user: $user) {
      user {
        id
        firstName
        lastName
        address
        email
        phone
        lastLogin
      }
    }
  }`;

  modifyUserMutation = gql`mutation ModifyUser($user: UserModifyInput) {
    modifyUser(user: $user) {
      user {
        id
        firstName
        lastName
        address
        email
        phone
        lastLogin
        language
      }
    }
  }`;

  deleteUserMutation = gql`mutation RemoveUser($user: UserIdentInput) {
    removeUser(user: $user) {
      user {
        id
        firstName
        lastName
        address
        email
        phone
        lastLogin
      }
    }
  }`;

  assignCompanyUserMutation = gql`mutation AssignCompanyUser($company: CompanyUsersInput) {
    assignCompanyUser(company: $company) {
      company {
        id
        users {
          id
        }
      }
    }
  }`;

  unassignCompanyUserMutation = gql`mutation UnassignCompanyUser($company: CompanyUsersInput) {
    unassignCompanyUser(company: $company) {
      company {
        id
        users {
          id
        }
      }
    }
  }`;

  permissionsGetUserRolesQuery = gql`query PermissionsGetUserRoles($user: UserIdentInput, $company: CompanyIdentInput) {
    permissionsGetUserRoles(user: $user, company: $company) {
      user {
        id
        permissions {
          roles {
            id
            title
          }
          perms {
            id
            def
            title
          }
        }
      }
    }
  }`;

  emailSendApprovalMessageQuery = gql`query EmailSendApprovalMessage($company: CompanyIdentInput) {
    emailSendApprovalMessage(company: $company) {
      emailSentNum
      sendErrors {
        to
        __error_message
        __msg_id
      }
    }
  }`;

  companyGetByIdQuery = gql`query CompanyGetById($company: CompanyIdentInput, $options: SelectOptions) {
    companyGetById(company: $company, options: $options) {
      company {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
        deleted
      }
    }
  }`;

  constructor(private apollo: Apollo) {}

  getCompanies(data, options?, search = '') {
    console.log(options);

    return this.apollo.watchQuery({
      query: this.getCompaniesQuery,
      fetchPolicy: 'network-only',
      variables: {
        company: data,
        options,
        search
      }
    });
  }

  getCompaniesOnce(data, options?, search = '') {
    // console.log(data);

    return this.apollo.query({
      query: this.getCompaniesOnceQuery,
      fetchPolicy: 'network-only',
      variables: {
        company: data,
        options,
        search
      }
    });
  }

  searchCompanies(search, filter) {
    // console.log(data);

    return this.apollo.query({
      query: this.searchCompaniesQuery,
      fetchPolicy: 'network-only',
      variables: {
        search,
        filter
      }
    });
  }

  getCompanyById(id) {
    return this.apollo.query({
      query: this.getCompanyByIdQuery,
      variables: {
        company: {
          id: id
        }
      }
    });
  }

  addCompany(data, options?, search = '') {
    // console.log(data);

    const country = data.country;

    return this.apollo.mutate({
      mutation: this.addCompanyMutation,
      variables: {
        company: data
      },
      refetchQueries: [{
        query: this.getCompaniesQuery,
        variables: {
          company: {
            country,
          },
          options,
          search
        },
      }],
    });
  }

  deleteCompany(id, country) {
    // console.log(id);
    return this.apollo.mutate({
      mutation: this.deleteCompanyMutation,
      variables: {
        company: {
          id: id
        }
      },
      updateQueries: {
        CompanyGetByFilter: (prev, { mutationResult }) => {
          // console.log(prev['companyGetByFilter']['companies']);
          // console.log(mutationResult.data['deleteCompany']['company']);
          // const newComment = mutationResult.data.submitComment;
          return {
            companyGetByFilter: {
              __typename: 'CompanyListType',
              companies: [...prev['companyGetByFilter']['companies'].filter(item => item.id !== mutationResult.data['deleteCompany']['company'].id)]
            }
          };
        },
      },
    });
  }

  modifyCompany(id, data, options?, search = '') {
    console.log(data);

    console.log(id);

    delete data.taxId;

    const country = data.country;

    delete data.country;

    // console.log(Object.assign({}, data, {id}));

    return this.apollo.mutate({
      mutation: this.modifyCompanyMutation,
      variables: {
        company: Object.assign({}, data, {id})
      },
      refetchQueries: [{
        query: this.getCompaniesQuery,
        variables: {
          company: {
            country
          },
          options,
          search
        },
      }, {
        query: this.companyGetByIdQuery,
        variables: {
          company: {
            id
          }
        },
      }]
    });
  }

  modifyCompanyTaxId(id, taxId) {
    return this.apollo.mutate({
      mutation: this.modifyCompanyTaxIdMutation,
      variables: {
        company: {
          id,
          taxId
        }
      }
    });
  }

  activateCompany(id, country) {
    return this.apollo.mutate({
      mutation: this.activateCompanyMutation,
      variables: {
        company: {
          id
        }
      },
      refetchQueries: [{
        query: this.getCompaniesQuery,
        variables: {
          company: {
            country
          }
        },
      }]
    });
  }

  convertCompanyToCustomer(id, country, options?, search = '') {
    return this.apollo.mutate({
      mutation: this.convertCompanyToCustomerMutation,
      variables: {
        company: {
          id
        }
      },
      refetchQueries: [{
        query: this.getCompaniesQuery,
        variables: {
          company: {
            country
          },
          options,
          search
        },
      }, {
        query: this.getUsersQuery,
        variables: {
          company: {
            id
          }
        },
      }]
    });
  }

  convertCompanyBackFromCustomer(id, country, options?, search = '') {
    return this.apollo.mutate({
      mutation: this.convertCompanyBackFromCustomerMutation,
      variables: {
        company: {
          id
        },
        options
      },
      refetchQueries: [{
        query: this.getCompaniesQuery,
        variables: {
          company: {
            country
          },
          options,
          search
        },
      }]
    });
  }

  companySetStatusDeclined(id, country, options?, search = '') {
    return this.apollo.mutate({
      mutation: this.companySetStatusDeclinedMutation,
      variables: {
        company: {
          id
        },
        options
      },
      refetchQueries: [{
        query: this.getCompaniesQuery,
        variables: {
          company: {
            country
          },
          options,
          search
        },
      }]
    });
  }

  companySetStatusPending(id, country, options?, search = '') {
    return this.apollo.mutate({
      mutation: this.companySetStatusPendingMutation,
      variables: {
        company: {
          id
        },
        options
      },
      refetchQueries: [{
        query: this.getCompaniesQuery,
        variables: {
          company: {
            country
          },
          options,
          search
        },
      }]
    });
  }

  addUser(companyId, data) {
    delete data.role;
    delete data.operator;
    delete data.title;

    // console.log(id, data);

    return this.apollo.mutate({
      mutation: this.addUserMutation,
      variables: {
        user: data
      },
    });
  }

  modifyUser(id, test, companyId?) {
    const userRole = test.role;

    console.log(userRole);

    delete test.role;
    delete test.operator;
    delete test.email;
    delete test.title;

    console.log(userRole);

    // console.log(test);
    // console.log(Object.assign({}, test, {id}));

    return this.apollo.mutate({
      mutation: this.modifyUserMutation,
      variables: {
        user: Object.assign({}, test, {id})
      },
      refetchQueries: [{
        query: this.getUsersQuery,
        variables: {
          company: {
            id: companyId
          }
        },
      }, {
        query: this.getUserByIdQuery,
        variables: {
          user: {
            id
          },
        },
      }, {
        query: this.permissionsGetUserRolesQuery,
        variables: {
          user: {
            id
          },
          company: {
            id: companyId || -1
          }
        },
      }],
    });
  }

  deleteUser(id, companyId?) {
    // console.log(id);
    return this.apollo.mutate({
      mutation: this.deleteUserMutation,
      variables: {
        user: {
          id
        }
      },
    });
  }

  assignCompanyUser(companyId, userId, roleId = 3) {
    // console.log(companyId, userId);
    return this.apollo.mutate({
      mutation: this.assignCompanyUserMutation,
      variables: {
        company: {
          id: companyId,
          users: [{
            id: userId
          }],
          roleId
        },
      },
      refetchQueries: [{
        query: this.getUsersQuery,
        variables: {
          company: {
            id: companyId
          }
        },
      }, {
        query: this.permissionsGetUserRolesQuery,
        variables: {
          user: {
            id: userId
          },
          company: {
            id: companyId || -1
          }
        },
      }],
    });
  }

  unassignCompanyUser(companyId, userId) {
    // console.log(companyId, userId);
    return this.apollo.mutate({
      mutation: this.unassignCompanyUserMutation,
      variables: {
        company: {
          id: companyId,
          users: [{
            id: userId
          }]
        },
      },
      refetchQueries: [{
        query: this.getUsersQuery,
        variables: {
          company: {
            id: companyId
          }
        },
      }],
    });
  }

  getUsers(id) {
    // console.log(id);

    return this.apollo.watchQuery({
      query: this.getUsersQuery,
      fetchPolicy: 'network-only',
      variables: {
        company: {
          id
        }
      }
    });
  }

  getUsersOnce(id) {
    // console.log(id);

    return this.apollo.query({
      query: this.getUsersQuery,
      fetchPolicy: 'network-only',
      variables: {
        company: {
          id
        }
      }
    });
  }

  userGetById(id) {
    // console.log(id);

    return this.apollo.watchQuery({
      query: this.getUserByIdQuery,
      fetchPolicy: 'network-only',
      variables: {
        user: {
          id
        }
      }
    });
  }

  userGetByIdOnce(id) {
    // console.log(id);

    return this.apollo.query({
      query: this.getUserByIdQuery,
      fetchPolicy: 'network-only',
      variables: {
        user: {
          id
        }
      }
    });
  }

  permissionsGetUserRoles(userId, companyId) {
    return this.apollo.watchQuery({
      query: this.permissionsGetUserRolesQuery,
      fetchPolicy: 'network-only',
      variables: {
        user: {
          id: userId
        },
        company: {
          id: companyId || -1
        }
      }
    });
  }

  emailSendApprovalMessage(companyId) {
    // console.log(id);
    return this.apollo.query({
      query: this.emailSendApprovalMessageQuery,
      variables: {
        company: {
          id: companyId
        },
      }
    });
  }
}
