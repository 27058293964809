import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import awsconfigprod from './aws-exports.prod';

if (environment.production) {
  Amplify.configure(awsconfigprod);

  enableProdMode();
} else {
  Amplify.configure(awsconfig);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
