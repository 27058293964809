import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS, Apollo} from 'apollo-angular';
import {HttpLinkModule, HttpLink} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloLink} from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { Auth } from 'aws-amplify';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {DefaultOptions} from 'apollo-client';

const uri = environment.apiUrl; // <-- add the URL of the GraphQL server here
// export function createApollo(httpLink: HttpLink) {
//   return {
//     link: httpLink.create({uri}),
//     cache: new InMemoryCache(),
//   };
// }

// const defaultOptions: DefaultOptions = {
//   watchQuery: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'ignore',
//   },
//   query: {
//     fetchPolicy: 'no-cache',
//     errorPolicy: 'all',
//   },
// };

export function createApollo(httpLink: HttpLink) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));

  // Get the authentication token from local storage if it exists
  // const token = sessionStorage.getItem('token');

  const auth = setContext(async (operation, context) => {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false
      });

      return {
        headers: {
          Authorization: `${user.signInUserSession.accessToken.jwtToken}`
        }
      };
  });

  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
    // defaultOptions
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {
  constructor(apollo: Apollo,
              httpLink: HttpLink) {
    const http = httpLink.create({ uri: uri });
    const middleware = new ApolloLink((operation, forward) => {

      operation.setContext({
        headers: new HttpHeaders().set(
          'X-API-KEY',
          environment.apiKey,
        ),
      });
      return forward(operation);
    });
    const link = middleware.concat(http);

    apollo.createNamed('unauth',{
      link: link,
      cache: new InMemoryCache(),
      // defaultOptions
    });
  }
}
