// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://wewx33xaqfbw3njylmh7qrm4yi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:a9977dc6-57a5-407c-9fa7-a35028de3899",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_O7S73DHgk",
    "aws_user_pools_web_client_id": "6q7i6rd1e34hv0caav7bj4kdio",
};


export default awsmobile;
