import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-companies-status-modal',
  templateUrl: './companies-status-modal.component.html',
  styleUrls: ['./companies-status-modal.component.scss']
})
export class CompaniesStatusModalComponent implements OnInit {
  @Output() close = new EventEmitter();

  constructor(@Inject('company') public company,
              private router: Router,
              @Inject('data') public data) { }

  ngOnInit() {
  }

  closeModal(): void {
    this.close.emit();
  }

  proceed() {
    this.close.emit();

    this.data.next(this.company.id);
  }
}
