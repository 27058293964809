import {Component, Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class RoundsService {
  getRoundByIdQuery = gql`query RoundById($round: RoundIdentInput, $options: SelectOptions) {
        roundById(round: $round, options: $options) {
          round {
            id
            roundNumber
            country
            countries
            currency
            managerId
            status
            preliminaryDate
            finalRunDate
            ppMode
            currencies {
              currency
              rate
            }
          }
        }
      }`;

  getRoundByIdWatchQuery = gql`query RoundById($round: RoundIdentInput, $options: SelectOptions) {
        roundById(round: $round, options: $options) {
          round {
            id
            roundNumber
            country
            countries
            currency
            managerId
            status
            preliminaryDate
            finalRunDate
            ppMode
            currencies {
              currency
              rate
              lead
            }
          }
        }
      }`;

  getLastRoundQuery = gql`query RoundGetLast($round: RoundCountryInput, $options: SelectOptions) {
        roundGetLast(round: $round, options: $options) {
          round {
            id
            status
            currency
          }
        }
      }`;

  createRoundMutation = gql`mutation CreateNewRound($round: RoundDataInput) {
    createNewRound(round: $round) {
      round {
        id
        roundNumber
        country
        currency
        managerId
        status
        preliminaryDate
        finalRunDate
        ppMode
      }
    }
  }`;

  modifyRoundMutation = gql`mutation ModifyRound($round: RoundModifyInput) {
        modifyRound(round: $round) {
          round {
            id
            roundNumber
            country
            currency
            managerId
            status
            preliminaryDate
            finalRunDate
            ppMode
          }
        }
      }`;

  deleteRoundMutation = gql`mutation DeleteRound($round: RoundIdentInput) {
        deleteRound(round: $round) {
          round {
            id
          }
        }
      }`;

  // closePreliminaryRoundMutation = gql`mutation closePreliminaryRound($round: RoundIdentInput) {
  //       closePreliminaryRound(round: $round) {
  //         round {
  //           id
  //         }
  //       }
  //     }`;

  modifyCountryMutation = gql`mutation CountryModify($country: CountryInput) {
    countryModify(country: $country) {
      alpha3
      currency
      title
      mainMessage
      subMessage
    }
  }`;

  changeRoundStatusMutation = gql`mutation ChangeRoundStatus($round: RoundStatusInput) {
        changeRoundStatus(round: $round) {
          round {
            id
            roundNumber
            country
            currency
            managerId
            status
            preliminaryDate
            finalRunDate
          }
        }
      }`;

  getCountryOperatorsQuery = gql`query GetCountryOperators($user: UserOperatorInput, $options: SelectOptions) {
    getCountryOperators(user: $user, options: $options) {
      users {
        id
        firstName
        lastName
        address
        email
        phone
        role
        lastLogin
      }
      countries
    }
  }`;

  runAngorithmQuery = gql`query AlgoExec($round: RoundId) {
        algoExec(round: $round) {
          algo {
            status
            timestamp
          }
        }
      }`;

  postProcessQuery = gql`query PostProcess($round: RoundId) {
        postProcess(round: $round)
      }`;

  emailSendUploadInvitationQuery = gql`query EmailSendUploadInvitation($round: RoundIdentInput, $country: String) {
    emailSendUploadInvitation(round: $round, country: $country) {
      emailSentNum
      sendErrors {
        to
        __error_message
        __msg_id
      }
    }
  }`;

  countriesGetAllQuery = gql`query CountriesGetAll {
    countriesGetAll {
      alpha3
      currency
      title
      mainMessage
      subMessage
    }
  }`;

  distributeNotifyingResultsQuery = gql`query DistributeNotifyingResults($round: RoundId, $processType: XlsProcessType) {
    distributeNotifyingResults(round: $round, processType: $processType)
  }`;

  distributePreliminaryResultsQuery = gql`query DistributePreliminaryResults($round: RoundId, $processType: XlsProcessType) {
    distributePreliminaryResults(round: $round, processType: $processType)
  }`;

  distributeFinalResultsQuery = gql`query DistributeFinalResults($round: RoundId, $processType: XlsProcessType) {
    distributeFinalResults(round: $round, processType: $processType)
  }`;

  getZipReportSignedUrlQuery = gql`query GetZipReportSignedUrl($hash: String, $reportType: ReportType, $roundId: ID) {
    getZipReportSignedUrl(hash: $hash, reportType: $reportType, roundId: $roundId) {
      progress
      link
    }
  }`;

  getUnifiedReportSignedUrlQuery = gql`query GetUnifiedReportSignedUrl($reportType: ReportType, $roundId: ID) {
    getUnifiedReportSignedUrl(reportType: $reportType, roundId: $roundId) {
      progress
      link
    }
  }`;

  getRunInfoQuery = gql`query GetRunInfo($round: RoundId!) {
    getRunInfo(round: $round) {
      totalSettled
      lastRun
      companiesNum
    }
  }`;

  isAlgoFinishedQuery = gql`query IsAlgoFinished($round: RoundId!, $timestamp: String!) {
    isAlgoFinished(round: $round, timestamp: $timestamp)
  }`;

  roundAddCountryMutation = gql`mutation RoundAddCountry($round: RoundIdentInput) {
    roundAddCountry(round: $round) {
      round {
        id
        roundNumber
        country
        countries
        currency
        managerId
        status
        preliminaryDate
        finalRunDate
        ppMode
      }
    }
  }`;

  roundRemoveCountryMutation = gql`mutation RoundRemoveCountry($round: RoundIdentInput) {
    roundRemoveCountry(round: $round) {
      round {
        id
        roundNumber
        country
        countries
        currency
        managerId
        status
        preliminaryDate
        finalRunDate
        ppMode
      }
    }
  }`;

  roundAddCurrencyMutation = gql`mutation RoundAddCurrency($round: RoundIdentInput) {
    roundAddCurrency(round: $round) {
      round {
        id
        roundNumber
        country
        countries
        currency
        managerId
        status
        preliminaryDate
        finalRunDate
        ppMode
        currencies {
          currency
          rate
        }
      }
    }
  }`;

  roundRemoveCurrencyMutation = gql`mutation RoundRemoveCurrency($round: RoundIdentInput) {
    roundRemoveCurrency(round: $round) {
      round {
        id
        roundNumber
        country
        countries
        currency
        managerId
        status
        preliminaryDate
        finalRunDate
        ppMode
        currencies {
          currency
          rate
        }
      }
    }
  }`;

  constructor(private apollo: Apollo) {}

  getRoundById(id) {
    return this.apollo.query({
      query: this.getRoundByIdQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: id
        }
      }
    });
  }

  getRoundByIdWatch(id) {
    return this.apollo.watchQuery({
      query: this.getRoundByIdWatchQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: id
        }
      }
    });
  }

  getLastRound(country) {
    return this.apollo.watchQuery({
      query: this.getLastRoundQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          country
        }
      }
    });
  }

  createRound(data) {
    // console.log(data);
    return this.apollo.mutate({
      mutation: this.createRoundMutation,
      variables: {
        round: data
      },
      updateQueries: {
        RoundGetLast: (prev, { mutationResult }) => {
          // console.log(prev);
          // console.log(mutationResult);
          // const newComment = mutationResult.data.submitComment;
          return {
            roundGetLast: {
              __typename: 'RoundType',
              round: {
                id: mutationResult['data']['createNewRound']['round']['id'],
                status: mutationResult['data']['createNewRound']['round']['status'],
                currency: mutationResult['data']['createNewRound']['round']['currency'],
                country: mutationResult['data']['createNewRound']['round']['country'],
                __typename: 'RoundId',
              }
            }
          };
        },
      },
    });
  }

  modifyRound(data) {
    // console.log(data);
    return this.apollo.mutate({
      mutation: this.modifyRoundMutation,
      variables: {
        round: data
      }
    });
  }

  deleteRound(id) {
    // console.log(id);
    return this.apollo.mutate({
      mutation: this.deleteRoundMutation,
      variables: {
        round: {
          id: id
        }
      },
      updateQueries: {
        RoundGetLast: (prev, { mutationResult }) => {
          // console.log(prev);
          // console.log(mutationResult);
          // const newComment = mutationResult.data.submitComment;
          return {
            roundGetLast: null
          };
        },
      },
    });
  }

  // closePreliminaryRound(id) {
  //   // console.log(id);
  //   return this.apollo.mutate({
  //     mutation: this.closePreliminaryRoundMutation,
  //     variables: {
  //       round: {
  //         id: id
  //       }
  //     }
  //   });
  // }

  modifyCountry(alpha3, mainMessage, subMessage) {
    // console.log(id);
    return this.apollo.mutate({
      mutation: this.modifyCountryMutation,
      variables: {
        country: {
          alpha3,
          mainMessage,
          subMessage
        }
      },
      refetchQueries: [{
        query: this.countriesGetAllQuery,
      }],
    });
  }

  changeRoundStatus(id, status) {
    // console.log(id);
    return this.apollo.mutate({
      mutation: this.changeRoundStatusMutation,
      variables: {
        round: {
          id: id,
          status: status
        }
      },
      updateQueries: {
        RoundGetLast: (prev, { mutationResult }) => {
          // console.log(prev);
          // console.log(mutationResult);
          // const newComment = mutationResult.data.submitComment;
          return {
            roundGetLast: {
              __typename: 'RoundType',
              round: {
                id: mutationResult['data']['changeRoundStatus']['round']['id'],
                status: mutationResult['data']['changeRoundStatus']['round']['status'],
                currency: mutationResult['data']['changeRoundStatus']['round']['currency'],
                __typename: 'RoundId',
              }
            }
          };
        },
      },
    });
  }

  runAlgorithm(id) {
    // console.log(id);
    return this.apollo.query({
      query: this.runAngorithmQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id
        }
      }
    });
  }

  getCountryOperators(country) {
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: this.getCountryOperatorsQuery,
      variables: {
        user: {
          country
        }
      }
    });
  }

  postProcess(id) {
    // console.log(id);
    return this.apollo.query({
      query: this.postProcessQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id
        }
      }
    });
  }

  emailSendUploadInvitation(id, country) {
    // console.log(id);
    return this.apollo.query({
      query: this.emailSendUploadInvitationQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id
        },
        country
      }
    });
  }

  distributeNotifyingResults(roundId, processType = 'email') {
    return this.apollo.query({
      query: this.distributeNotifyingResultsQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        processType
      }
    });
  }

  distributePreliminaryResults(roundId, processType = 'email') {
    return this.apollo.query({
      query: this.distributePreliminaryResultsQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        processType
      }
    });
  }

  distributeFinalResults(roundId, processType = 'email') {
    return this.apollo.query({
      query: this.distributeFinalResultsQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        processType
      }
    });
  }

  getZipReportSignedUrl(hash, reportType, roundId) {
    return this.apollo.query({
      query: this.getZipReportSignedUrlQuery,
      fetchPolicy: 'network-only',
      variables: {
        hash,
        reportType,
        roundId
      }
    });
  }

  getUnifiedReportSignedUrl(reportType, roundId) {
    return this.apollo.query({
      query: this.getUnifiedReportSignedUrlQuery,
      fetchPolicy: 'network-only',
      variables: {
        reportType,
        roundId
      }
    });
  }

  isAlgoFinished(roundId, timestamp) {
    return this.apollo.query({
      query: this.isAlgoFinishedQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        },
        timestamp,
      }
    });
  }

  getRunInfo(roundId) {
    return this.apollo.query({
      query: this.getRunInfoQuery,
      fetchPolicy: 'network-only',
      variables: {
        round: {
          id: roundId
        }
      }
    });
  }

  roundAddCountry(id, country) {
    return this.apollo.mutate({
      mutation: this.roundAddCountryMutation,
      variables: {
        round: {
          id,
          country
        }
      },
      refetchQueries: [{
        query: this.getRoundByIdWatchQuery,
        variables: {
          round: {
            id
          }
        },
      }],
    });
  }

  roundRemoveCountry(id, country) {
    return this.apollo.mutate({
      mutation: this.roundRemoveCountryMutation,
      variables: {
        round: {
          id,
          country
        }
      },
      refetchQueries: [{
        query: this.getRoundByIdWatchQuery,
        variables: {
          round: {
            id
          }
        },
      }],
    });
  }

  roundAddCurrency(id, currencies) {
    return this.apollo.mutate({
      mutation: this.roundAddCurrencyMutation,
      variables: {
        round: {
          id,
          currencies
        }
      },
      refetchQueries: [{
        query: this.getRoundByIdWatchQuery,
        variables: {
          round: {
            id
          }
        },
      }],
    });
  }

  roundRemoveCurrency(id, currencies) {
    return this.apollo.mutate({
      mutation: this.roundRemoveCurrencyMutation,
      variables: {
        round: {
          id,
          currencies
        }
      },
      refetchQueries: [{
        query: this.getRoundByIdWatchQuery,
        variables: {
          round: {
            id
          }
        },
      }],
    });
  }

}
