import {Component, Inject, OnInit, Renderer} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-signup-duplicate',
  templateUrl: './signup-duplicate.component.html',
  styleUrls: ['./signup-duplicate.component.scss']
})
export class SignupDuplicateComponent implements OnInit {
  username;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.username = params['username'];
    });
  }

  clearStorage() {
    localStorage.removeItem('auth');
    localStorage.removeItem('userId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('assigned');
  }

}
