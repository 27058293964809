import { NgModule } from '@angular/core';
import {HomeComponent} from '../../components/home/home.component';
import {HeaderComponent} from '../../components/header/header.component';
import {
  ButtonModule, CheckboxModule,
  ComboBoxModule, DatePickerModule, DialogModule, DropdownModule, InputModule,
  LoadingModule, ModalModule,
  NotificationModule, NumberModule, PaginationModule,
  PlaceholderModule, RadioModule, SelectModule, SkeletonModule,
  TableModule,
  UIShellModule
} from 'carbon-components-angular';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StepsComponent} from '../../components/steps/steps.component';
import {MatFormFieldModule, MatSelectModule} from '@angular/material';
import {InternationalPhoneNumberModule} from 'ngx-international-phone-number';
import * as moment from 'moment';

@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    StepsComponent,
  ],
  imports: [
    NotificationModule,
    UIShellModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    ComboBoxModule,
    AmplifyAngularModule,
    PlaceholderModule,
    LoadingModule,
    InputModule,
    SelectModule,
    DropdownModule,
    ModalModule,
    TranslateModule,
    CommonModule,
    ButtonModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    RadioModule,
    DialogModule,
    DatePickerModule,
    PaginationModule,
    MatSelectModule,
    MatFormFieldModule,
    InternationalPhoneNumberModule,
    SkeletonModule,
    NumberModule,
  ],
  exports: [
    NotificationModule,
    UIShellModule,
    ComboBoxModule,
    AmplifyAngularModule,
    PlaceholderModule,
    LoadingModule,
    InputModule,
    SelectModule,
    DropdownModule,
    ModalModule,
    TranslateModule,
    CommonModule,
    ButtonModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    RadioModule,
    DialogModule,
    DatePickerModule,
    PaginationModule,
    StepsComponent,
    MatSelectModule,
    MatFormFieldModule,
    InternationalPhoneNumberModule,
    SkeletonModule,
    NumberModule,
  ],
  providers: [
    DatePipe,
    { provide: 'moment', useFactory: (): any => moment }
  ],
})
export class BaseModule { }
