import {Component, Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class PermissionsService {
  permissionsListQuery = gql`query PermissionsList {
    permissionsList {
      permissions {
        roles {
          id
          title
        }
        perms {
          id
          def
          title
        }
      }
    }
  }`;

  permissionsRoleListQuery = gql`query PermissionsRoleList {
    permissionsRoleList {
      permissions {
        roles {
          id
          title
        }
        perms {
          id
          def
          title
        }
      }
    }
  }`;

  permissionsGetUserRolesQuery = gql`query PermissionsGetUserRoles($user: UserIdentInput, $company: CompanyIdentInput) {
    permissionsGetUserRoles(user: $user, company: $company) {
      user {
        id
        permissions {
          roles {
            id
            title
          }
          perms {
            id
            def
            title
          }
        }
      }
    }
  }`;

  permissionsAssignUserRoleMutation = gql`mutation PermissionsAssignUserRole($user: UserWithPermissionsInput) {
    permissionsAssignUserRole(user: $user) {
      user {
        id
        permissions {
          roles {
            id
            title
          }
          perms {
            id
            def
            title
          }
        }
      }
    }
  }`;

  constructor(private apollo: Apollo) {}

  permissionsList() {
    return this.apollo.query({
      query: this.permissionsListQuery,
    });
  }

  permissionsRoleList() {
    return this.apollo.query({
      query: this.permissionsRoleListQuery,
    });
  }

  permissionsGetUserRoles(userId, companyId) {
    return this.apollo.query({
      query: this.permissionsGetUserRolesQuery,
      fetchPolicy: 'network-only',
      variables: {
        user: {
          id: userId
        },
        company: {
          id: companyId || -1
        }
      }
    });
  }

  permissionsAssignUserRole(userId, roles, companyId) {
    console.log(roles);
    let modifiedRoles = roles.slice(0);

    console.log(modifiedRoles);

    modifiedRoles = modifiedRoles.filter(item => {
      return item != null;
    }).map(item => {
      return {
        id: parseInt(item.id, 0),
        // title: item.content
      };
    });

    console.log(modifiedRoles);

    return this.apollo.mutate({
      mutation: this.permissionsAssignUserRoleMutation,
      variables: {
        user: {
          id: userId,
          permissions: {
            roles: modifiedRoles,
            // perms: [{
            //   id: '',
            //   def: '',
            //   title: ''
            // }]
          }
        },
      },
      refetchQueries: [{
        query: this.permissionsGetUserRolesQuery,
        variables: {
          user: {
            id: userId
          },
          company: {
            id: companyId || -1
          }
        },
      }],
    });
  }
}
