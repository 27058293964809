import {Component, Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class DetailsService {
  addUserMutation = gql`mutation AddUser($user: UserAddInput) {
    addUser(user: $user) {
      user {
        id
        firstName
        lastName
        address
        email
        phone
        lastLogin
      }
    }
  }`;

  modifyUserMutation = gql`mutation ModifyUser($user: UserModifyInput) {
    modifyUser(user: $user) {
      user {
        id
        firstName
        lastName
        address
        email
        phone
        lastLogin
      }
    }
  }`;

  addCompanyMutation = gql`mutation AddCompany($company: CompanyInput) {
    addCompany(company: $company) {
      company {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
      }
    }
  }`;

  modifyCompanyMutation = gql`mutation ModifyCompany($company: CompanyModifyInput) {
    modifyCompany(company: $company) {
      company {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
      }
    }
  }`;

  assignCompanyUserMutation = gql`mutation AssignCompanyUser($company: CompanyUsersInput) {
    assignCompanyUser(company: $company) {
      company {
        id
        users {
          id
        }
      }
    }
  }`;

  modifyCompanyTaxIdMutation = gql`mutation ModifyCompanyTaxId($company: CompanyTaxIdInput) {
    modifyCompanyTaxId(company: $company) {
      company {
        id
        taxId
        country
        name
        address
        isCustomer
        canBePrepaid
        isGovernmental
        status
        deleted
      }
    }
  }`;

  constructor(private apollo: Apollo) {}

  addUser(data) {
    delete data.role;

    return this.apollo.use('unauth').mutate({
      mutation: this.addUserMutation,
      variables: {
        user: data
      }
    });
  }

  modifyUser(id, data) {
    delete data.role;
    delete data.operator;
    delete data.email;

    // console.log(data);
    // console.log(Object.assign({}, data, {id}));

    return this.apollo.mutate({
      mutation: this.modifyUserMutation,
      variables: {
        user: Object.assign({}, data, {id})
      },
    });
  }

  modifyUserEmail(id, data) {
    return this.apollo.mutate({
      mutation: this.modifyUserMutation,
      variables: {
        user: Object.assign({}, data, {id})
      },
    });
  }

  addCompany(data) {
    // console.log(data);
    return this.apollo.mutate({
      mutation: this.addCompanyMutation,
      variables: {
        company: data
      },
    });
  }

  modifyCompany(id, data) {
    delete data.taxId;
    delete data.country;

    // console.log(Object.assign({}, data, {id}));

    return this.apollo.mutate({
      mutation: this.modifyCompanyMutation,
      variables: {
        company: Object.assign({}, data, {id})
      }
    });
  }

  modifyCompanyTaxId(id, taxId) {
    return this.apollo.mutate({
      mutation: this.modifyCompanyTaxIdMutation,
      variables: {
        company: {
          id,
          taxId
        }
      }
    });
  }

  assignCompanyUser(companyId, userId, roleId = 3) {
    // console.log(companyId, userId);
    return this.apollo.mutate({
      mutation: this.assignCompanyUserMutation,
      variables: {
        company: {
          id: companyId,
          users: [{
            id: userId
          }],
          roleId
        },
      }
    });
  }
}
