import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss']
})
export class FileUploadModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  titles = {
    replace: this.translate.instant('upload.file.modal.replace'),
    append: this.translate.instant('upload.file.modal.append')
  };

  constructor(@Inject('action') public action,
              private router: Router,
              public translate: TranslateService) { }

  ngOnInit() {
  }

  closeModal(): void {
    this.close.emit();
  }

  proceed(action) {
    this.close.emit();

    this.router.navigate(['/home/upload'], { queryParams: { action }, queryParamsHandling: 'merge' });
  }
}
