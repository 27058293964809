import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from 'carbon-components-angular';
import {DetailsService} from '../../services/details.service';
import {AppService} from '../../app.service';
import {CompaniesService} from '../../services/companies.service';
import {HelperService} from '../../services/helper.service';
import {PermissionsService} from '../../services/permissions.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Auth} from 'aws-amplify';
import {Subscription, timer} from 'rxjs';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: [
    '../auth/auth.scss',
    './create-company.component.scss'
  ]
})
export class CreateCompanyComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  formGroup: FormGroup;
  isLoading = false;
  stack = false;
  userId;
  companyId;
  countries = [];
  assigned;
  email;
  skip = false;
  company;
  @ViewChild('customNotificationContent', {static: true}) public customNotificationContent: TemplateRef<any>;
  countriesActive = [];
  countyEnabled;
  countryEmail;
  lang;
  creating = false;

  constructor(private amplifyService: AmplifyService,
              protected formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private notificationService: NotificationService,
              private router: Router,
              private detailsService: DetailsService,
              private appService: AppService,
              private companiesService: CompaniesService,
              private permissionsService: PermissionsService,
              private http: HttpClient,
              public translate: TranslateService,
              private helperService: HelperService) {
  }

  ngOnInit() {
    this.countries = this.appService.getCountries();

    this.countries = this.countries.map(item => {
      return {
        content: item[this.translate.currentLang],
        code: item.code,
        en: item.en,
        mk: item.mk,
        hr: item.hr,
        sr: item.sr,
        cn: item.cn,
        el: item.el,
        alpha2: item.alpha2
      };
    }).sort((a, b) => {
      return a.content.localeCompare(b.content);
    });

    this.subscription.add(this.translate.onLangChange
      .subscribe(data => {
        this.lang = data['lang'];

        this.countries = this.countries.map(item => {
          return {
            content: item[this.lang],
            code: item.code,
            en: item.en,
            mk: item.mk,
            hr: item.hr,
            sr: item.sr,
            cn: item.cn,
            el: item.el,
            alpha2: item.alpha2
          };
        }).sort((a, b) => {
          return a.content.localeCompare(b.content);
        });
      }));

    this.appService.countriesGetActive()
      .subscribe(data => {
        this.countriesActive = data['data']['countriesGetActive'];
        }, (error) => {
        this.showNotification('error', error);

        console.log('there was an error sending the query', error);
      });

    this.formGroup = this.formBuilder.group({
      companyName: ['', [
        Validators.required,
        Validators.pattern(/.*\S+.*/),
      ]],
      country: ['', Validators.required],
      taxId: ['', [
        Validators.pattern(/.*\S+.*/),
        Validators.pattern(/^\d+$/),
        Validators.required
      ]],
      address1: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      address2: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      city: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      state: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
      postcode: ['', [
        Validators.pattern(/.*\S+.*/),
      ]],
    });

    if (JSON.parse(localStorage.getItem('auth'))) {
      const data = JSON.parse(localStorage.getItem('auth'));

      this.formGroup.patchValue(data);
    }

    Auth.currentUserInfo()
      .then(user => {
        this.userId = user.attributes['custom:user_id'];
      })
      .catch(err => {
        this.isLoading = false;

        this.showNotification('error', err['message']);
      });
  }

  showNotification(type, message) {
    this.notificationService.showNotification({
      type,
      title: 'Auth',
      message,
      target: '.notification-container',
      duration: 30000
    });
  }

  showNotificationDuplicate(type) {
    this.notificationService.showNotification({
      type,
      title: this.translate.instant('company.details.dup'),
      target: '.tax-id-notification-container',
      template: this.customNotificationContent,
      taxId: this.formGroup.get('taxId').value
    });
  }

  isErrorVisible(field, error, select?) {
    if (select) {
      return this.formGroup.controls[field].touched
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else if (field == 'taxId'
      && this.formGroup.get('country').value && this.helperService.oneOf(this.formGroup.get('country').value, ['BIH', 'MKD', 'SRB', 'MNE'])
      && this.formGroup.controls[field].value
    ) {
      const illegal = !this.appService.validateTaxControl(
        this.formGroup.controls[field].value,
        this.formGroup.get('country').value
      );

      this.formGroup.controls[field].setErrors(illegal ? { illegal } : null);

      return this.formGroup.controls[field].dirty
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    } else {
      return this.formGroup.controls[field].dirty
        && this.formGroup.controls[field].errors
        && this.formGroup.controls[field].errors[error];
    }
  }

  buildCountryEmail(currentActiveCountry) {
    if (currentActiveCountry) {
      this.countryEmail = `${currentActiveCountry}-Manager@rollsoft.com`;
    } else {
      this.countryEmail = environment.serviceDesk;
    }
  }

  getOperatingCountry(country) {
    return this.countries.find(item => item.code === country).content;
  }

  addCompany() {
    document.getElementsByClassName('tax-id-notification-container')[0].innerHTML = '';

    this.creating = true;

    this.stack = true;

    this.isLoading = true;

    this.countyEnabled = this.countriesActive.find(item => item['alpha3'] === this.formGroup.get('country').value);

    this.buildCountryEmail(this.countyEnabled && this.countyEnabled['alpha3']);

    if (!this.countyEnabled) {
      this.isLoading = false;

      this.stack = false;

      this.showNotification(
        'warning',
        `${this.translate.instant('signup.operating', {country: this.getOperatingCountry(this.formGroup.get('country').value)})}`
      );

      timer(3000).subscribe(data => {
        this.isLoading = false;

        location.replace(`https://rollsoft.com/?lang=${this.translate.currentLang}`);
      });

      return false;
    }

    if (this.helperService.oneOf(this.formGroup.get('country').value, ['BIH', 'MKD', 'SRB', 'MNE'])
      && !this.appService.validateTaxControl(this.formGroup.get('taxId').value, this.formGroup.get('country').value)) {
      this.isLoading = false;

      this.stack = false;

      this.showNotification('error', `${this.translate.instant('signup.taxid.control')}`);

      return false;
    }

    this.detailsService.addCompany({
      country: this.formGroup.get('country').value,
      name: this.formGroup.get('companyName').value,
      taxId: this.formGroup.get('taxId').value,
      address: `${this.formGroup.get('postcode').value}
${this.formGroup.get('state').value}
${this.formGroup.get('city').value}
${this.formGroup.get('address1').value}
${this.formGroup.get('address2').value}`
    })
      .subscribe(data => {
        this.companyId = data['data']['addCompany']['company']['id'];

        this.assignCompanyUser();

        localStorage.removeItem('auth');

        this.sendEmailToManager();

        localStorage.setItem('currentCountry', JSON.stringify(this.formGroup.get('country').value));
      }, (error) => {
        this.isLoading = false;

        this.stack = false;

        this.creating = false;

        error.graphQLErrors.map((e) => {
          if (e.errorInfo && e.errorInfo.statusCode === 1001) {
            this.showNotificationDuplicate('warning');
          } else {
            this.showNotification('error', error);
          }
        });

        console.log('there was an error sending the query', error);
      });
  }

  sendEmailToManager() {
    this.isLoading = true;

    this.appService.sendEmailToManager(this.companyId)
      .subscribe(data => {
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;

        this.showNotification('error', error);

        console.log('there was an error sending the query', error);
      });
  }

  assignCompanyUser() {
    this.isLoading = true;

    this.detailsService.assignCompanyUser(this.companyId, this.userId, 1) // Admin
      .subscribe(data => {
        this.isLoading = false;

        this.stack = false;

        this.router.navigate(['/home']);
      }, (error) => {
        this.isLoading = false;

        this.showNotification('error', error);

        console.log('there was an error sending the query', error);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
