// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  amountRegex: '',
  apiUrl: 'https://yy7zsftdanhrjmfq5cguepjway.appsync-api.us-east-1.amazonaws.com/graphql',
  apiKey: 'da2-wpubjrjtsjc4do4ojuv5v2pem4',
  ipiApi: 'https://ipapi.co/json/?key=jyazDurFj1ZWY50M4XnGzr8T9puSDKSiMqrxHwHptug8OAUvvW',
  serviceDesk: 'ServiceDesk-dev@rollsoft.com',
  idleInterval: 60,
};

// https://stackoverflow.com/questions/2227370/currency-validation
// https://regexr.com/4q9hf
// https://regexr.com/4vo52

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
