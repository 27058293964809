import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  signedIn = false;

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject): any => {
      Auth.currentAuthenticatedUser({
        bypassCache: false
      }).then(user => {
        console.log(user);

        if (user) {
          resolve(true);
        } else {
          reject(false);
        }
      }, error => {
        this.router.navigate(['/signin'], { queryParams: { return_url: state.url }});
      });
    });
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    const search = window.location.search;
    const fullPath = segments.reduce((path, currentSegment) => {
      return `${path}/${currentSegment.path}`;
    }, '');

    return new Promise((resolve, reject): any => {
      Auth.currentAuthenticatedUser({
        bypassCache: false
      }).then(user => {
        console.log(user);

        if (user) {
          resolve(true);
        } else {
          reject(false);
        }
      }, error => {
        this.router.navigate(['/signin'], { queryParams: { return_url: fullPath + search }});
      });
    });
  }
}
