import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-companies-duplicates-modal',
  templateUrl: './companies-duplicates-modal.component.html',
  styleUrls: ['./companies-duplicates-modal.component.scss']
})
export class CompaniesDuplicatesModalComponent implements OnInit {
  @Output() close = new EventEmitter();

  constructor(@Inject('companies') public companies,
              private router: Router,
              @Inject('confirm') public confirm,
              @Inject('id') public id) { }

  ngOnInit() {
  }

  closeModal(): void {
    this.close.emit();
  }

  proceed() {
    this.close.emit();

    this.confirm.next(this.id);
  }
}
